@import "../config/vars";

.btn {
    background-color: var(--color-black);
    border-radius: 5px;
    border: 1px solid var(--color-black);
    padding: 0.5rem;
    margin: 0 0.5rem;
    font-size: 13px;
    font-weight: bold;
    &:hover {
        text-decoration: none;
    }
    @media (min-width: 1024px) {
        font-size: 16px;
        padding: 0.5rem 1rem;
    }
    &__login {
        border: 1px solid var(--color-yellow);
        &:hover{
            border: 1px solid var(--color-white);
            color: var(--color-white);
        }
    }
    &__register {
        color: var(--color-blue);
        background-color: var(--color-yellow);
        &:hover{
            border: 1px solid var(--color-white);
            color: var(--color-white);
        }
    }
    &__section-buttons {
        margin: 0.5rem;
        margin-bottom: 0.5rem;
        color: var(--color-blue);
        background-color: var(--color-yellow);
        padding: 0.5 2rem;
        &:hover {
            background-color: var(--color-white);
            color: var(--color-black);
        }
    }
    &__registro {
        background-color: var(--color-yellow);
        border: 1px solid var(--color-yellow);
        color: var(--color-blue);
        padding: 1rem 2rem;
        font-size: 18px;
        font-weight: bold;
        margin: 0.5rem auto;
        &__img {
            max-width: 100px;
        }
        &:hover {
            background-color: var(--color-white);
            color: var(--color-black);
        }
    }
    &__banca {
        background-color: var(--color-white);
        color: var(--color-blue);
        padding: 0.5rem 2rem;
        position: absolute;
        bottom: 1rem;
    }
    &__gallos {
        background-color: var(--color-yellow);
        border: 1px solid var(--color-yellow);
        color: var(--color-blue);
        padding: 0.5rem 3rem;
        font-size: 18px;
        font-weight: bold;
        margin: 0.5rem auto;
    }
    &__footer{
        background-color: var(--color-yellow);
        border: 1px solid var(--color-yellow);
        color: var(--color-blue);
        padding: 1rem 2rem;
        font-size: 18px;
        font-weight: bold;
        margin: 0.5rem auto;
    }
}