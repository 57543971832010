@import "../config/vars";

.section-footer {
    color: var(--color-blue);
    strong{
        color: var(--color-blue);
    }

    h1 {
        @include section-title;
    }

    .footer {
        &__container {
            @include container-general;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
.terminos{
    border-collapse: collapse;
    tr:nth-child(2n-1){
        background-color: #efefef;
    }
    td{
        border: 1px solid var(--color-blue);
        padding: 1rem;
    }
}
