@mixin clearMenu {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	margin-left: 0;
}
@mixin section-title {
	color: var(--color-blue);
	background-color: var(--color-white);
	border-bottom: 2px solid var(--color-blue);
	text-align: center;
	padding: 0.5rem;
}
@mixin container-general {
	max-width: 1200px;
	margin: 0 auto;
	padding: 2rem;
	background-color: var(--color-white);
	border: 1px solid var(--color-blue);
	border-radius: 10px;
	color: var(--color-blue);
	margin-bottom: 2rem;
}