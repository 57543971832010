@import "../config/vars";
.providers{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    &__content{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-blue);
        border-radius: 20px;
        margin: 0.5rem;
        min-height: 80px;
        &:hover{
            background-color: #4800c5;
        }
    }
    &__img{
        width: 80px;
        &:hover{
            width: 100px;
        }
    }
}