.tns-carousell {
  &>div:nth-child(odd) {
    background: var(--color-first);
  }

  &>div:nth-child(even) {
    background: var(--color-third);
  }

  &__content {
    width: 60px;
    margin: 0 auto;

    span {
      color: white;
    }
  }

  &__img {
    margin: 0 auto;
    max-width: 50%;
    max-height: 100%;
  }

  @media (min-width: 640px) {
    &__img {
      max-width: 80%;
    }
  }
}

.slider-container {
  max-width: 90%;
  margin: 0 auto;

  &__title {
    text-align: center;
    color: var(--color-blue);
  }
}