@import "../config/vars";
.section-caballos{
    h1{
        @include section-title;
    } 
}
.caballos{
    &__container{
        @include container-general;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__table{
        overflow: scroll;
        table{
            border-collapse: collapse;
        }
        th, td{
            border: 1px solid blue;
        }
    }
    &table, th, td{
        padding: 0.5rem 4rem;
    }

}