@import "../config/vars";
.section-apuestas{
    h1{
        @include section-title;
    }
    .apuestas{
        &__container-text{
            @include container-general;
        }
    }
}