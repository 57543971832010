//layout
:root {
	--header-height: 3rem;

	@include from(l) {
		--header-height: 4rem;
	}

	//native color vars
	--color-bg			: #fff;
	--color-black		: #131314;
	--color-white		: #fff;
	--color-ligthgray	: #d3cdcd;
	--color-yellow		: #f7c544;
	--color-text		: #ffc400;
	--color-blue		: #002cc5;
	--color-gray        : #808080;

	// Font families
	--font-size: 16px;
	--line-height: 1.6;
}