@import "../config/vars";
.section-casino
{
    h1{
        @include section-title;
    }
    .casino{
        &__container-text{
            @include container-general;
        }
    }
}