@import '../config/vars';

.bottom-nav {
	background: var(--color-black);
	color: var(--color-text);
	border-top: 5px solid var(--color-blue);
	padding: 2rem 1rem 1rem 1rem;

	&__menu {
		padding: 0 1rem;
	}
	&__anchor {
		&:hover {
			text-decoration: none;
			color: var(--color-white);
		}
	}
	&__img {
		padding: 1rem 0;
	}
	&__title{
		font-weight: bold;
	}
	&__socialicon{
		margin: 2rem 0;
	}
	&__socialicons{
		display: flex;
	}
	&__container-icon{
		margin: 0 0.5rem;
	}
	&__icon{
		width: 30px;
	}
	&__curacao{
		background-color: black;
		padding: 1rem;
		max-width: 100%;
		min-height: 100px;
		display: flex;
		justify-content: center;
		&__img{
			max-width: 70px;
			height: auto;
		}
	}
}