@import '../config/vars';
.sport-rules-container {
	padding: 20px;
	background-color: var(--color-white);
	box-shadow: var(--color-white);
	border-radius: 15px;
	margin-bottom: 40px;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	color: var(--color-blue);
	strong{
		color: var(--color-blue);
	}

	h1{
		font-size: 24px;
	}
	h2{
		font-size: 16px;
	}
	td, tr{
		text-align: center;
	}
}

.sport-rules__table {
	border-collapse: collapse;
}

.sport-rules__table--td1 {
	width: 20%;
	padding: 5px;
	border: 1px solid var(--color-blue);
}

.sport-rules__table--td2 {
	border: 1px solid var(--color-blue);
	padding: 5px;
}
