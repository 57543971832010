.tns-single{
    & > div:nth-child(odd){
      background        : var(--color-first);
    }
    & > div:nth-child(even){
      background        :var(--color-third);
    }
    &__content{
      background        : transparent;
      span{color        : white;}
    }
  }