@import "../config/vars";

.modal-login {
    max-width: 1200px;
    padding: 2rem;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 10%;
    margin-left: -9.8rem;
    border-radius: 1rem;
    z-index: 10;
    display: none;

    &__forgot-pass{
        margin: 0 auto;
        &:hover{
            color: var(--color-yellow);
        }
    }
}
@media (min-width: 960px) {
    .modal-login {
        margin-left: -28.5rem;
    }
}

.login {
    border-radius: 10px;
    background-color: var(--color-blue);
    color: var(--color-white);
    max-width: 1200px;
    display: flex;
    margin: auto;

    &__img {
        display: none;
    }

    @media (min-width: 960px) {
        &__img {
            display: block;
            min-width: 500px;
        }
    }

    &__content {
        width: 100%;
        min-width: 20vw;
        padding: 2rem;

        &__header {
            margin-bottom: 4rem;
            text-align: center;

            &__title {
                margin: 0;
            }

            &__text {
                color: var(--color-text);
                font-size: 12px;
            }
        }

        &__form {
            display: flex;
            flex-direction: column;

            &__label {
                color: var(--color-white);
            }

            &__input {
                background-color: var(--color-blue);
                border-radius: 5px;
                padding: 0.5rem;
                border: 1px solid var(--color-white);
                color: var(--color-white);
                margin: 0.5rem 0;
                margin-bottom: 2rem;

                &::placeholder {
                    color: var(--color-white);
                }
            }

            &__checkbox {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: var(--color-text);
                margin: 1rem 0;
                input{
                    margin: 0.5rem;
                    margin-bottom: 0;
                }
            }

            &__text {
                text-align: center;
                margin: 0;
                margin-top: 0.5rem;
            }

            &__links {
                display: flex;
                justify-content: center;
                margin: 0.5rem 0;
            }

            &__img {
                margin: 0 0.5rem;
                max-width: 100px;
            }
        }
    }

    &__close {
        width: 3rem;
        height: 3rem;
        color: white;
        font-size: 3rem;
        position: absolute;
        top: 1rem;
        right: 2rem;

        &::before {
            content: '×';
        }
    }
}