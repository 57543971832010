.container-registro{
    width: 100%;
    height: auto;
    background-color: var(--color-blue);
    padding: 2rem 0;
    &__form{
        padding: 2rem 0;
    }
    &__iframe{
        width: 100%;
        height: 800px;
        border: none;
    }
    &__title{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-bottom: 3rem;
    }
    &__h1{
        color: white;
        margin: 0;
        line-height: 1.5rem;
    }
    &__link{
        text-decoration: underline;
    }
}