@import "../config/vars";

.section-bonus {
    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
            grid-row: span 2;
        }
    }
    &__img{
        border-radius: 10px;
    }
    h1 {
        @include section-title;
    }
}