@import '../config/vars';

.section-contacto{
    background-color: var(--color-ligthgray);
    text-align: center;
    color: var(--color-blue);
    border-top: 1px solid var(--color-blue);
    margin: 0;
    padding: 0.5rem;
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__img{
        margin: 0.5rem 0;
        max-width: 120px;
        height: auto;
    }
}