img {
	border: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}

// Código en línea
:not(pre) code {
	background: var(--color-light);
	color: var(--third-color);
	padding: 0 .25em;
	margin: 0 .1em;
	word-break: break-all;
}

.main-footer {
	ul {
		li {
			list-style: none;
		}
	}
}

a {
	text-decoration: none;
	color: var(--link-color);
	transition: all .3s ease-out;

	&:hover {
		color: var(--link-hover-color);
	}
}

::-webkit-scrollbar {
	display: none;
}

*:focus {
	outline: none;
}

.no-padding {
	padding-left: 0;
	padding-right: 0;
}
.linkTerms{
	text-decoration: underline;
	color: var(--color-text);
}
strong{
	color: var(--color-text);
}
.tns-controls button {
	display: none;
}