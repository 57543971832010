@import "../config/vars";

.bonus {
    display: none;
    &__content {
        position: absolute;
        top: 10%;
        background-color: var(--color-blue);
        color: var(--color-white);
        max-width: 800px;
        z-index: 10;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 100rem rgba(0, 0, 0, 0.300);
    }
    @media (min-width: 640px) {
        &__content {
            margin: 0 10%;
        }
    }
    @media (min-width: 1200px) {
        &__content {
            margin: 0 29%;
        }
    }
    p {
        margin: 0.5rem;
    }
    &__container {
        padding: 2rem;
        h1 {
            margin: 0;
            margin-top: 2rem;
            font-size: 80px;
            color: var(--color-text);
            text-align: center;
            line-height: 4rem;
            font-weight: bold;
        }
        h2 {
            font-size: 22px;
            color: var(--color-white);
            text-align: center;
        }
        p {
            color: var(--color-white);
        }
    }
    &__footer {
        background-color: var(--color-black);
        padding: 1rem;
        text-align: center;
        &h2 {
            font-weight: bold;
        }
    }
    &__close {
        width: 4rem;
        height: 4rem;
        color: white;
        font-size: 3rem;
        position: absolute;
        top: 0rem;
        right: 0rem;

        &::before {
            content: '×';
        }
    }
}