@import '../config/vars';
.accordion-container {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
}
.accordion {
	width: 100%;
	height: auto;
}
.accordion .a-container .a-btn {
	position: relative;
	padding: 1rem 2rem;
	font-weight: bold;
	background-color: var(--color-ligthgray);
	cursor: pointer;
	transition: all 0.4s ease-in-out;
	border-radius: 5px;
}
.accordion .a-container .a-btn span {
	display: block;
	position: absolute;
	height: 14px;
	width: 14px;
	right: 20px;
	top: 18px;
}
.accordion .a-container .a-btn span:after {
	content: "";
	width: 14px;
	height: 3px;
	border-radius: 2px;
	background-color: var(--color-blue);
	position: absolute;
	top: 6px;
}
.accordion .a-container .a-btn span:before {
	content: "";
	width: 14px;
	height: 3px;
	border-radius: 2px;
	background-color: var(--color-blue);
	position: absolute;
	top: 6px;
	transform: rotate(90deg);
	transition: all 0.4s ease-in-out;
}
.accordion .a-container .a-panel {
	width: 100%;
	transition: all 0.4s ease-in-out;
	opacity: 0;
	height: auto;
	max-height: 0;
	overflow: hidden;
}
.accordion .a-container.active .a-btn {
	color: var(--color-black);
}
.accordion .a-container.active .a-btn span::before {
	transform: rotate(0deg);
}
.accordion .a-container.active .a-panel {
	padding: 0 2rem;
	opacity: 1;
	max-height: 100%;
	background-color: var(--color-white);
}