.tns-promotion {
    &>div:nth-child(odd) {
        background: var(--color-first);
    }
    &>div:nth-child(even) {
        background: var(--color-third);
    }
    &__content {
        background: transparent;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 0.5rem;
        span {
            color: white;
        }
    }
    &__img{
        border-radius: 10px;
    }
}