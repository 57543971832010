*,
*:after,
*:before {
	box-sizing 			: inherit;
	padding 			: 0;
	margin 				: 0;
}

html {
	box-sizing 			: border-box;
	font-size 			: var(--font-size);
	line-height 		:  var(--line-height);
	color 				: var(--color-text);
	background-color 	: var(--color-bg);
}

::selection {
	background-color 	: var(--color-bg);
	color 				: var(--color-text);
}

body {
	margin         		: var(--header-height) 0 0 0;
	min-height     		: calc(100vh - var(--header-height));
	background 			: var(--body-bg);
	display        		: flex;
	flex-direction 		: column;
	overflow-x 			: hidden;
}