@import '../config/vars';

.top-nav {
	background: var(--color-black);
	position: fixed;
	border-bottom: 2px solid var(--color-yellow);
	top: 0;
	width: 100%;
	left: 0;
	display: flex;
	justify-content: center;
	z-index: 4;
	&__container {
		display: flex;
		justify-content: space-between;
		height: var(--header-height);
		width: 100%;
		max-width: 1200px;
		padding: 0 15px;
	}
	&__link {
		align-self: center;
	}
	&__logo {
		max-height: 2rem;
		display: block;
	}
	&__menu {
		@include fromTo(s, l) {
			position: fixed;
			padding: 0 10px;
			height: 100vh;
			width: 260px;
			background: var(--color-black);
			transition: right .5s;
			overflow-y: auto;
			top: var(--header-height);
			right: -260px;
			&.is-active {
				right: 0;
			}
		}
		@include from(l) {
			display: flex;
			align-self: center;
			margin: 0;
			padding: 0;
		}
	}
	&__li {
		@include fromTo(s, l) {
			display: block;
			padding: 10px 0;
			margin: 0;
		}
		margin-left: 1em;
		list-style-type: none;
		display: inline-block;
	}
	&__anchor {
		@include fromTo(s, l) {
			display: block;
		}
		&:hover {
			color: var(--color-yellow);
			text-decoration: none;
		}
	}
	&__mb-iconMenu {
		position: relative;
		align-self: center;
		top: 3px;
		padding: 0;
		span {
			&.hamburger-inner {
				background: var(--color-yellow);
				&::after,
				&::before {
					background: var(--color-yellow);
				}
			}
		}
		&:focus {
			outline: 0 !important;
			outline: thin dotted;
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
		@include from(l) {
			display: none;
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__lang{
		display: inline-block;
	}
	&__imglang{
		max-width: 25px;
		height: auto;
	}
}